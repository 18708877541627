<template>
  <div class="login">
    <v-container class="text-xs-center">
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success btn-back"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-layout child-flex justify-center align-center wrap class="verifikasi">
        <v-flex fill-height>
          <v-card flat>
            <v-card-title primary-title class="justify-center">
              <h3 class="m-auto">Verifikasi OTP</h3>
              <p class="text-center verif-text">
                Masukkan kode verifikasi yang telah dikirimkan melalui SMS ke
                nomor berikut ***{{ slice_no_hp }}
              </p>
            </v-card-title>
            <v-form>
              <otp-input
                v-model="verifikasi_data.code_otp"
                class="style-1"
                :length="6"
                pattern="[^0-9]+"
                :ignorePattern="false"
                fieldClass="custom-field-class"
                :size="32"
              />
              <v-alert type="error" v-show="response !== null">
                {{ response }}</v-alert
              >
              <v-card-actions>
                <v-btn large block class="btn-login" @click="Register"
                  >Verifikasi</v-btn
                >
              </v-card-actions>
              <p class="text-center">
                Silahkan klik kirim lagi dalam {{ countDown }} detik
              </p>
              <a class="white" v-show="linkactive">
                <p @click="resendOtp()" class="text-center">Kirim lagi</p>
              </a>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { ResendOtp, Register } from "../services/api";
import helper from "../helpers/auth";

export default {
  data: () => ({
    verifikasi_data: {
      nama: "",
      no_hp: "",
      gender: "",
      email: "",
      tanggal_lahir: "",
      password: "",
      code_otp: "",
    },
    resend_otp_data: {
      hp: "",
    },
    user_data: {
      email: "",
      gender: "",
      id: "",
      nama: "",
      no_hp: "",
      tanggal_lahir: "",
      token: "",
    },
    slice_no_hp: "",
    countDown: 30,
    linkactive: false,
    response: null,
  }),
  mounted() {
    this.verifikasi_data.no_hp = this.$route.params.no_hp;
    this.verifikasi_data.nama = this.$route.params.nama;
    this.verifikasi_data.gender = this.$route.params.gender;
    this.verifikasi_data.email = this.$route.params.email;
    this.verifikasi_data.tanggal_lahir = this.$route.params.tanggal_lahir;
    this.verifikasi_data.password = this.$route.params.password;
    this.resend_otp_data.hp = this.$route.params.no_hp;
    this.slice_no_hp = this.$route.params.slice_hp;
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    Register() {
      console.log(this.token);
      Register(this.verifikasi_data)
        .then((data) => {
          this.$store.dispatch("SET_USER", data);
          this.user_data.token = data.data.Token;
          this.user_data.email = data.data.data.email;
          this.user_data.gender = data.data.data.gender;
          this.user_data.id = data.data.data.id;
          this.user_data.nama = data.data.data.nama;
          this.user_data.no_hp = data.data.data.no_hp;
          this.user_data.password = data.data.data.password;
          this.user_data.tanggal_lahir = data.data.data.tanggal_lahir;
          console.log(this.user_data);
          helper.setData(this.user_data);
          this.$router.push({
            name: "Home",
          });
        })
        .catch((err) => {
          console.log(err);
          this.response = err.response.data.message;
        });
    },
    resendOtp() {
      ResendOtp(this.resend_otp_data)
        .then((data) => {
          console.log(data);
          this.countDown = 30;
           this.countDownTimer();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
        this.linkactive = false;
      }
      if (this.countDown == 0) {
        this.linkactive = true;
      }
    },
  },
  created() {
    this.countDownTimer();
  },
};
</script>
<style>
@import "../assets/css/verifikasi.component.scss";
</style>